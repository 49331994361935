import appraisal from  '../components/appraisal';
import salary from  '../components/salary';
export default 
    [
        {
            id: 1,
            title: 'Appraisal',
            component: appraisal,
            isActive: true
        },
        {
            id: 2,
            title: 'Salary',
            component: salary,
            isActive: false
        }
    ]

<template> 
    <FormWrapper>
        <PageTitle title="Update Staff Appraisal" slot="title" link="/helpContent/UpdateStaffAppraisal" />
        <Wizard :steps="steps"/>
            <component :is="activeStep.component"
                ref="component"
                :form="form"
                :stepId="activeStep.id"
                :class="$style.formContent"
                @nextStep="nextStep"
                  @prevStep="prevStep"

            />
    </FormWrapper>
</template>

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import Wizard from 'Components/wizard/Wizard.vue';
    import formStepsMixin from 'Mixins/formStepsMixin';
    import STEPS from './steps'
    import {staffApprasial} from "../api";
    import { ValidationObserver } from "vee-validate";
    import PageTitle from 'Components/layout/PageTitle';
    export default {
        name: 'mainPage',
        mixins: [formStepsMixin],
        components: {
            FormWrapper,
            Wizard,
            PageTitle,
            // ValidationObserver
        },
        data() {
            return {
                form: {
                selectedStaff:null,
                appraisedBy: '',
                designation:null,
                grade: null,
                appraisalOn: "",
                effectiveFrom: "",
                basicSalary: null,
                additional: '',
                rating:null,
                pfDeduction: "",
                },
                showSaveButton: false,
                infoMessage: {
                    isVisible: false,
                    message: ''
                },
            }
        },
        computed: {
            userData() {
                return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.setSteps(STEPS);
            this.setActiveStep(1);
        },
        methods: {
            nextStep() {
                const nextStep = this.activeStep.id + 1;
                // // this.$refs.validator.validate().then((res) => {
                // //     if (res) {
                         this.moveStep(nextStep);
                //     }
                // });
        
              
                 
            
            },
            prevStep() {
                const nextStep = this.activeStep.id - 1;
                this.moveStep(nextStep);
            },
            resetActivatedSteps(){
                this.steps.forEach(item => {
                    if(item.id !== 1){
                        item.isDisabled = true;
                    }
                })
            },
            activateSteps(step) {
                this.steps.forEach(item => {
                    if(item.id === step){
                        item.isDisabled = false;
                    } else{
                        item.isDisabled = true;
                    }
                })
            },
            handleUpdate(nextStep){
              console.log("handleUpdate",nextStep);
            },
            moveStep(nextStep){
                this.activateSteps(nextStep);
                    this.setActiveStep(nextStep);
            },
        },
    }
</script>

<style lang="scss" module>
    .formContent {
        h6 {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        p {
            text-align: justify;
            margin-bottom: 20px;
        }
        ol {
            padding-left: 40px;
            margin-bottom: 20px;
            li {
                margin-bottom: 10px;
            }
        }
    }
</style>
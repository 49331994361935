<template>
  <ValidationObserver ref="validator">
    <FormRow>
      <div class="col-sm-2">
        <FormSelect
          label="Select Staff"
          :items="staffList"
          item-name="Name"
          item-value="id"
          :onChange="getStaffData"
          v-model="form.selectedStaff"
          rules="required"
        />
      </div>
      <div class="col-sm-2">
        <FormSelect
          label="Appraised By"
          :items="appraisedByList"
          item-name="NAME"
          item-value="id"
          v-model="form.appraisedBy"
          rules="required"
        />
      </div>
      <div class="col-sm-2">
        <inputDate
          label="Appraisal On"
          v-model="form.appraisalOn"
          rules="required"
        />
      </div>
      <div class="col-sm-2">
        <inputDate
          label="Effective From"
          v-model="form.effectiveFrom"
          rules="required"
        />
      </div>
    </FormRow>
    <FormRow>
      <!-- <div class="col-sm-4">
        <FormSelect
          label="Designation"
          :items="designationList"
          item-name="NAME"
          item-value="ID"
          v-model="form.designation"
          rules="required"
        />
      </div> -->
    </FormRow>
    <FormRow>
      <div class="col-sm-2">
        <FormSelect
          label="Designation"
          :items="designationList"
          item-name="NAME"
          item-value="ID"
          v-model="form.designation"
          rules="required"
        />
      </div>
      <div class="col-sm-2">
        <FormSelect
          label="Grade"
          :items="gradeList"
          item-name="Grade"
          item-value="Grade"
          v-model="form.grade"
          rules="required"
        />
      </div>
      <div class="col-sm-2">
        <InputAmount
          label="Basic "
          v-model="form.basicSalary"
          rules="required"
        />
      </div>
      <!-- <div class="col-sm-4">
        <InputAmount
          label="Additional  "
          v-model="form.additional"
          rules="required"
        />
      </div> -->
    </FormRow>
    <FormRow>
      <div class="col-sm-1">
        <FormSelect
          label="Rating"
          :items="ratingList"
          item-name="rating"
          item-value="rating"
          v-model="form.rating"
          rules="required"
        />
      </div>
      <div class="col-sm-3" style="display: flex;column-gap: 20px;">
        <label> PF Deduction</label>
        <InputYesNo
          label="PF Deduction"
          :Yes="1"
          :No="0"
          v-model="form.pfDeduction"
          rules="required"
        />
      </div>

      <!-- <div class="col-sm-4">
          <InputAmount  label="PF Amount  " v-model="form.pfAmount" rules="required" />
        </div> -->
    </FormRow>
    <ButtonGroup>
            <!-- <FormButton type="primary" @click="prevStep" v-if="showButtons.prev" left-icon="ios-arrow-back">Previous</FormButton> -->
            <!-- <FormButton type="success" v-if="showSaveButton" @click="sendData()">Save</FormButton> -->
            <FormButton type="primary" @click="nextStep"  right-icon="ios-arrow-forward">Next</FormButton>
        </ButtonGroup>
  </ValidationObserver>

</template>
<script>

import FormWrapper from 'Components/form/FormWrapper';
import loadingMixin from "Mixins/loadingMixin";
import {
  getAllStaff,
  getRating,
  getGrades,
  getDesignation,
  getMainUsers,
  getDetailByStaffId,
} from "../api";
import InputDate from "Components/form/InputDate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
export default {
  name: "index",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    // FormWrapper,
    InputDate,
    ValidationObserver,
  },
  props: ['form'],
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },

      staffList: [],
      appraisedByList: [],
      designationList: [],
      gradeList: [],
      ratingList: [],
      reasonList: [],
      isValid: false,
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  created() {
    this.getDropDownData();
  },
  methods: {
    getStaffData(e) {
      getDetailByStaffId({ staffId: e }).then((res) => {
        this.form.appraisedBy = res.data[0].Manager_id;
        this.form.designation = res.data[0].Designation_id;
        this.form.grade = res.data[0].Grade;
        this.form.effectiveFrom = res.data[0].EffectiveFrom;
        this.form.appraisalOn = res.data[0].AppraisedOn;
      });
    },
    async getDropDownData() {
      await getAllStaff().then((res) => {
        this.staffList = res.data;
      });
      await getMainUsers().then((res) => {
        this.appraisedByList = res.data;
      });

      await getRating().then((res) => {
        this.ratingList = res.data;
      });
      await getGrades().then((res) => {
        this.gradeList = res.data;
      });
      await getDesignation().then((res) => {
        this.designationList = res.data;
      });
    },
    getData() {
      const filters = {};
      filters.id = this.form.id;
      const data = {
        filterjson: {
          filter: [filters],
        },
      };
      leaveList(data).then((res) => {
        this.form.selectedStaff = res.data[0].staff_id;
        this.form.selectedLeaveType = res.data[0].LeaveType_id;
        this.form.appraisalOn = res.data[0].LeaveDateStart;
        this.form.effectiveFrom = res.data[0].LeaveDateEnd;
        this.form.reason = res.data[0].Reason;
        this.form.reasonCategoryId = res.data[0].LeaveType_id;
        this.form.firstDayLeaveType =
          res.data[0].FirstDayLeaveType == null
            ? ""
            : res.data[0].FirstDayLeaveType.toString();
        this.form.lastDayLeaveType =
          res.data[0].LastDayLeaveType == null
            ? ""
            : res.data[0].LastDayLeaveType.toString();
        this.form.leaveStatus = res.data[0].LeaveStatus;
        this.form.emergencyContacty = res.data[0].EmergencyContact;
      });
    },

    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result && this.isValid === false) {
          this.infoMessage.isVisible = false;
          this.showMessage.isVisible = false;
          const data = {
            staffId: this.form.selectedStaff,
            LeaveTypeId: this.form.selectedLeaveType,
            LeaveDateStart: this.form.appraisalOn,
            LeaveDateEnd: this.form.effectiveFrom,
            reason: this.form.reason,
            leaveReasonId: this.form.reasonCategoryId,
            firstDayLeaveType: parseInt(this.form.firstDayLeaveType),
            lastDayLeaveType: parseInt(this.form.lastDayLeaveType),
            emergencyContact: this.form.emergencyContacty,
            id: this.form.id,
          };
          const validate = {
            staffId: this.form.selectedStaff,
            leaveTypeId: this.form.selectedLeaveType,
            leaveDateStart: this.form.appraisalOn,
            leaveDateEnd: this.form.effectiveFrom,
            firstDayLeaveType: parseInt(this.form.firstDayLeaveType),
            lastDayLeaveType: parseInt(this.form.lastDayLeaveType),
          };
          validateLeave(validate).then((res) => {
            if (res.data[1][0].status > 1) {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.data[1][0].message;
            } else {
              applyStaffLeave(data).then((res) => {
                this.form = {};
                this.showMessage.isVisible = true;
                this.showMessage.message = res.data[0].message;
              });
            }
          });
        }
      });
    },
    nextStep() {
    
                this.$refs.validator.validate().then((res) => {
                    if (res) {
                  this.$emit("nextStep")
                    }
                });
        
              
                 
            
            },

    getLeaveRecord() {
      console.log("in");
    },
    async getAllList() {},
  },
};
</script>
<style lang="scss" module>
.error {
  color: #ff3547;
  margin-top: 2px;
  max-width: 100%;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}
</style>
